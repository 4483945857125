import axios from "axios";

export function useApi() {
    function obtemLojas() {
        return [
            { nome: "2KFashion", identificador: "2kfashion", },
            { nome: "818449", identificador: "ateli-dagih", },
            { nome: "A2F Chaveiro", identificador: "a2fchaveiro", },
            { nome: "Abba Artesanatos em Madeira Reciclada", identificador: "abba-artesanatos", },
            { nome: "ABU DABI", identificador: "kelvinpcds1212", },
            { nome: "A|C Água Cristalina Beachwear", identificador: "aguacristalinabeachwear", },
            { nome: "Açaí do Tinem O craque do açaí", identificador: "acaidotinem-ocraquedoacai", },
            { nome: "Açaí Encantado Delivery", identificador: "httpsinstagramcomacaiencantadodeliv", },
            { nome: "Açai e Pizzas Sabor Infinito", identificador: "saborinfinito", },
            { nome: "AC Climatização Ar Condicionado", identificador: "acclimatizacao", },
            { nome: "Ac - Studio Lash Design", identificador: "acstudiolash", },
            { nome: "adb construções e reformas", identificador: "tonybloghotmailcom", },
            { nome: "A&D ECOMMERCE", identificador: "a-e-d-ecommerce", },
            { nome: "Adega beira rio", identificador: "adegabeirario", },
            { nome: "adega da 2", identificador: "adeada2b", },
            { nome: "Adega Galdinos", identificador: "adegagaldinos1", },
            { nome: "adega LG", identificador: "adegalg", },
            { nome: "Adega & Louge Encontro Dos Naguileiros", identificador: "adega", },
            { nome: "adesivarte", identificador: "adesivarte", },
            { nome: "Adhesive Soluções Digitais", identificador: "adhesivegraphics", },
            { nome: "Adriana souza", identificador: "alenxandreferreira123415", },
            { nome: "Adriano cestas básicas", identificador: "adriano-cestas-bsicas", },
            { nome: "adriele mendes", identificador: "drimendes", },
            { nome: "adventure Buggy TUR", identificador: "adventure-buggy-tur", },
            { nome: "aee", identificador: "aeefunkids", },
            { nome: "AE PERSONALIZADOS", identificador: "aepersonalizados", },
            { nome: "A.F. CLEAN LIMPEZA E HIGIENIZAÇÃO DE ESTOFADOS", identificador: "afclean", },
            { nome: "Afefé Produtos Originais Importados", identificador: "httpswalinkey45yx", },
            { nome: "AF Moda Esportiva", identificador: "afmodaesportiva", },
            { nome: "AFONSO FRETES RÁPIDOS", identificador: "afonsofretesrapidos", },
            { nome: "Agabê Decore", identificador: "agabedecore", },
            { nome: "AGASAE COLCHÕES MAGNÉTICOS E TERAPÊUTICOS", identificador: "agasaecolchoesmagneticos", },
            { nome: "Agatinha Moda Baby", identificador: "agatinhamodababy", },
            { nome: "Age ateliê", identificador: "memydiasage", },
            { nome: "Agendamento de documentos", identificador: "httpsinstagramcomstor", },
            { nome: "Agendamento de Documentos", identificador: "agendamento20", },
            { nome: "Akikitem mais", identificador: "akikitemmais", },
            { nome: "A&L", identificador: "distribuidora-ael", },
            { nome: "Alabapade omi", identificador: "alabapadeomi", },
            { nome: "Alabastrorepre CNPJ:457845260001/48", identificador: "alabastrorepre", },
            { nome: "Alan drinks", identificador: "alangardelcgmailcom", },
            { nome: "Albor", identificador: "alborvendas", },
            { nome: "Alê Comida Caseira", identificador: "httpswwwfacebookcomalelanches109", },
            { nome: "Ale Doces", identificador: "aledoces22", },
            { nome: "Alessandro", identificador: "rodrigomendesmendes375gmailcom", },
            { nome: "Alex esquadrias", identificador: "alex-esquadrias", },
            { nome: "alexsat", identificador: "alexsat", },
            { nome: "ALFA TECH", identificador: "alfatech", },
            { nome: "Alho Mio", identificador: "lojaalhomilojaalhomio", },
            { nome: "Alika Owl T-shirts", identificador: "alikaowl-t-shirts", },
            { nome: "alline cosméticos", identificador: "allinecosmeticos", },
            { nome: "ALM CLIMATIZACAO E SERV ELETRICOS", identificador: "almclimatizacoeeservcoseletricos", },
            { nome: "A.L. montagens e reparos", identificador: "httpsyoutubeepfufo3t-di", },
            { nome: "Alquimia Home Baker", identificador: "alquimiahomebaker", },
            { nome: "Alugar_e_brincar", identificador: "alugarebrincar", },
            { nome: "Aly.produtos", identificador: "alyprodutos", },
            { nome: "am", identificador: "am-instalacao", },
            { nome: "Amar Alimentos", identificador: "amar-alimentos", },
            { nome: "A/M Esteticar", identificador: "amesteticar", },
            { nome: "Amor de mãe cosmético e perfumaria é acessórios", identificador: "amor-de-mae", },
            { nome: "Amor D'Salgados", identificador: "amordsalgados", },
            { nome: "Amorimcosmeticos", identificador: "amorim-cosmticos-loja", },
            { nome: "A Músical Flash", identificador: "httpssellershopeecombrportalsettingsshopprofile", },
            { nome: "Ana confecções", identificador: "anaconfeccoes", },
            { nome: "Ana&Elvis Modas", identificador: "anacarolina7martinezgmailcom", },
            { nome: "AnaGi Decoração Pegue e Monte", identificador: "anagi34", },
            { nome: "Ana Luisa Nail Design", identificador: "analuisanaildesign", },
            { nome: "Ana Modas Durães", identificador: "anamodas", },
            { nome: "Ana Rodrigues Ateliê", identificador: "anaalicer365gmailcom", },
            { nome: "Andrade Materiais de Construção S/A", identificador: "andrademateriais", },
            { nome: "Angela beleza em suas mãos", identificador: "beleza-em-suas-mos", },
            { nome: "angel brindes personalizados", identificador: "httpsinstagramcomangelbrindesperson", },
            { nome: "ANGELI ARTIGOS EDUCATIVOS", identificador: "angeliartigoseducativos", },
            { nome: "Angel nurse clelia Gonçalves Sampaio", identificador: "cleenfermeira", },
            { nome: "anice nunes cosméticos", identificador: "anicenunescosmticos", },
            { nome: "Anília Leite Studio cabelos", identificador: "anilialeite", },
            { nome: "Ani Rodrigues Store", identificador: "storeanirodrigues", },
            { nome: "Antônio bolos e tortas gourmet", identificador: "httpswamemessagei3wruqltjwuxn1", },
            { nome: "Apiário e Meliponario D.V.C", identificador: "apiario-e-meliponario-dvc", },
            { nome: "APS BOMBAS E MOTORES", identificador: "apsbem", },
            { nome: "Aquiles lanches", identificador: "rodrigofelix", },
            { nome: "Araújo variedades", identificador: "araujonascimentogmailcom", },
            { nome: "AR-CONDICIONADO", identificador: "ar", },
            { nome: "Ariane kennedy Braids", identificador: "arianekennedybraids", },
            { nome: "armarinho mirante", identificador: "armarinho", },
            { nome: "arm construção e reformas", identificador: "httpsphotosappgooglgqzdi53gyg6nirhf", },
            { nome: "Armin Willig - Corte a gravação a Laser", identificador: "armin-willig", },
            { nome: "ARR Pinturas Impermeabilizações e serviços", identificador: "arrpinturasserv", },
            { nome: "Arte 10 Artesanatos", identificador: "arte10artesanatos", },
            { nome: "Arte Elegance Mesa Posta", identificador: "arteelegancemesaposta", },
            { nome: "Arte gesso", identificador: "arte-gesso", },
            { nome: "ArteseUnhas", identificador: "arteseunhas", },
            { nome: "artesferro e metais", identificador: "lojametaisferrometais", },
            { nome: "Arte tattoo studio", identificador: "wilartetattoo", },
            { nome: "ArteZ Paper", identificador: "artezpaper", },
            { nome: "ArThiz", identificador: "atelierrecantodathiz", },
            { nome: "Arthur Modas", identificador: "arthurmodas", },
            { nome: "Art & Laços", identificador: "artelacosartesanais", },
            { nome: "Art's Top Barbearia", identificador: "artstopbarbearia", },
            { nome: "A.S.F ASSISTENCIA EM CELULAR", identificador: "asf-assistenciaemcelular", },
            { nome: "ATACADÃO GV", identificador: "atacadaogv", },
            { nome: "Atalaia Moda e acessrios", identificador: "httpswame5547992122743textatalaiamo", },
            { nome: "Ateliê Baby Geek", identificador: "ateliebabygeek", },
            { nome: "Ateliê Clara Laços eh Bordados", identificador: "httpswwwfacebookcomclaralacosehbord", },
            { nome: "ateliê Gil elshadday ffesta", identificador: "gil-ffesta", },
            { nome: "Ateliê Imagine Artesanato", identificador: "httpsinstagramcomatelieimagineartesanatoutmmediumcopylink", },
            { nome: "Ateliê Juju Bebê", identificador: "atelie-juju-bebe", },
            { nome: "Ateliê larprincesa", identificador: "httpsmpagola2rvrdhf", },
            { nome: "Ateliê Mimos da Mih", identificador: "mimosdamih", },
            { nome: "ateliê Rani Silva", identificador: "atelie-ranisilva", },
            { nome: "Atelier AyE", identificador: "atelieraye", },
            { nome: "Atelier da Gestante", identificador: "atelierdagestante", },
            { nome: "Ateliê Rosa Choque", identificador: "atelierosachoque23", },
            { nome: "Ateliê Vanessa Santos", identificador: "atelievanessasantos1025", },
            { nome: "Ateliê Zamberking", identificador: "ateliezamberking", },
            { nome: "Atletica Império", identificador: "aatleticaimperio", },
            { nome: "Aurélio serviços Express", identificador: "aurelioentregarapida", },
            { nome: "auto mecânica pitibull", identificador: "auto-mecnica", },
            { nome: "Avoador do Robinho", identificador: "avoadordorobinho0640", },
            { nome: "Azul", identificador: "azul", },
            { nome: "Baby Fashion", identificador: "babyfashion", },
            { nome: "Baianadacarioca lanchonete", identificador: "baianadacarioca-lanchonete", },
            { nome: "Banca da Economia", identificador: "banca-da-economia", },
            { nome: "barbearia do berrio", identificador: "barbearia-berrio", },
            { nome: "barbearia welcome", identificador: "barbeariawelcome2020gmailcom", },
            { nome: "Bar doce Lar", identificador: "45752015000144", },
            { nome: "Bar Dois Irmãos", identificador: "bar-dois-irmos", },
            { nome: "bar e lanchonete brutos lanches", identificador: "httpswwwfacebookcomrodolfosergio75", },
            { nome: "BATATAS & COMPLEMENTOS", identificador: "batatasecomplementos", },
            { nome: "Bazar da Carol Mattos", identificador: "bazardacarolmattos", },
            { nome: "Bela Crente", identificador: "belacrente", },
            { nome: "Beleza Feminina", identificador: "belezafeminina", },
            { nome: "belíssima", identificador: "belissima", },
            { nome: "BellaModaFitness", identificador: "kaawebeer", },
            { nome: "BemMeQuer Calçados", identificador: "pretasouzacalcadosbemmequer", },
            { nome: "bemmequer_intima.acessorios", identificador: "bemmequermm", },
            { nome: "Bem Me Quer Mimos", identificador: "bemequermimos", },
            { nome: "Benfica Nailss", identificador: "benficanailss", },
            { nome: "BF Express", identificador: "crdito-em-plataforma-bfexpress", },
            { nome: "BG universo sexy", identificador: "bguniversossexy", },
            { nome: "BHS IMPERIO", identificador: "httpsinstagramcombhsimperioutmmediumcopylink", },
            { nome: "Bianca semi joias", identificador: "biancajoias", },
            { nome: "Bibi Store", identificador: "bibistore", },
            { nome: "Bichinhos Peraltas Distribuidora", identificador: "bichinhosperaltas", },
            { nome: "Bijuterias e Acessórios", identificador: "httpswwwinstagramcombijuteriaseaces", },
            { nome: "bikebsb", identificador: "bikebsb", },
            { nome: "BILU kids", identificador: "httpswamemessagempi4idnpbm57n1", },
            { nome: "bioalquimias", identificador: "bioalquimias", },
            { nome: "Bira Films", identificador: "biranunespeliculas", },
            { nome: "biscoito barbeiro", identificador: "biscoitobarbeirorj", },
            { nome: "Boa Sombra UV", identificador: "httpswwwfacebookcomboasombrauv", },
            { nome: "BOB's INSTALAÇÃO MANUTENÇÃO ELETRICA", identificador: "bob-filipevitor", },
            { nome: "bolartesbahia", identificador: "bolosbahia", },
            { nome: "Bolos da Lih", identificador: "bolosdalih", },
            { nome: "bom Jardim", identificador: "bom-jardim", },
            { nome: "Boteco dos dois irmãos", identificador: "httpsdanilo-da-silva-gomesnegociosi", },
            { nome: "Boutique do Mimo", identificador: "boutiquedomimo7", },
            { nome: "BOUTIQUE \"D\"VITÓRIA", identificador: "ianasantos123", },
            { nome: "Boutiquepolly", identificador: "hotmailcom", },
            { nome: "BoxBeer", identificador: "boxbeer", },
            { nome: "Box do Pandinha", identificador: "boxdopandinha", },
            { nome: "B&P Serviços Elétricos", identificador: "bepservicoseletricos", },
            { nome: "bracks Burguer grill", identificador: "bracks", },
            { nome: "Brave Distribuidora", identificador: "bravedistribuidora", },
            { nome: "BREJA EXPRESS DISTRIBUIDORA", identificador: "brejaexpress", },
            { nome: "BRODOWSKI STORE", identificador: "brodowskistore-modaseletronicos", },
            { nome: "Bruna Tupperware", identificador: "brunatupperware", },
            { nome: "Bruno Figlia", identificador: "brunofiglia96", },
            { nome: "Bruno lanches", identificador: "keke", },
            { nome: "bruno monteiro tattoo", identificador: "httpsabrebiotattoo", },
            { nome: "Bru Variedades Acabamentos e Pinturas", identificador: "realizesuapinturacomigo", },
            { nome: "Burlamaqui Modas", identificador: "lojaburlamaquimodas", },
            { nome: "By Giuliana Luna", identificador: "bygiulianaluna", },
            { nome: "By Grazi", identificador: "bygrazi", },
            { nome: "Cacá Moda Íntima", identificador: "cacamodaintima", },
            { nome: "Cafeteria Cof Coffee", identificador: "cofcoffee", },
            { nome: "caldinho da Anne", identificador: "aneane510", },
            { nome: "Camelo Construções & Conexões", identificador: "deposito-constr", },
            { nome: "Camila tattoo", identificador: "camilatattooink", },
            { nome: "Canecasdahs", identificador: "canecasdahs", },
            { nome: "CANECAS KS", identificador: "canecasks", },
            { nome: "Canhotto Planejados", identificador: "canhottoplanejados", },
            { nome: "Cantina jb", identificador: "francivaldovalente7gmailcom", },
            { nome: "cantinho da criança", identificador: "babyboutiqueinfantil", },
            { nome: "cantinho da gaby", identificador: "cantinhodagaby", },
            { nome: "cantinho da Mery", identificador: "meryhelen2018gmailcom", },
            { nome: "Cantinho da Naine", identificador: "cantinhodanaine", },
            { nome: "Cantinho da Paz", identificador: "cantinhoepaz", },
            { nome: "Cantinho dos sabores", identificador: "httpantinho-dos-saboresjuju", },
            { nome: "Cantinho VRS", identificador: "cantinhovrs", },
            { nome: "capota mais não breca", identificador: "guillhermeferrari2021outlookcom", },
            { nome: "CARDOSO SERVIÇOS", identificador: "cardoso", },
            { nome: "Carina Massas Frescas", identificador: "carinamassasfrescas", },
            { nome: "Carlos Design", identificador: "vidixoski", },
            { nome: "Carvalho construção e reformas", identificador: "franciscoalvesdecarvalho", },
            { nome: "CarX Serviços Automotivos", identificador: "carxauto", },
            { nome: "Casa e Piscina", identificador: "casaepiscina", },
            { nome: "CASARAO MARMITARIA DELIVERY", identificador: "cardapio-do-diacontra-file-assadoar", },
            { nome: "casa Santana", identificador: "bolos-de-potes", },
            { nome: "Cascão Informática", identificador: "cascaoinfo", },
            { nome: "caseirinhos da Lu", identificador: "caseirinhos-dalu", },
            { nome: "cazonatto pão de mel", identificador: "delfinocesar37gmailcom", },
            { nome: "ceica mix variedades", identificador: "ceica-mix-variedades", },
            { nome: "Centenário Informática", identificador: "centenario", },
            { nome: "CENTRO DE BELEZA LOPES", identificador: "httpswamemessagequbiqzxv3vyvi1", },
            { nome: "cervejaria Alexs", identificador: "cervejaria-alexs", },
            { nome: "cestas girassol", identificador: "cestagirrasol15", },
            { nome: "Ceva do Bigode", identificador: "cevadobigode", },
            { nome: "CHARLES PROMO STORE", identificador: "lojameigratisapploja", },
            { nome: "Charme Delirante Moda Íntima", identificador: "cdmodaintima", },
            { nome: "chaveiro Universal", identificador: "lojameigratisapplojameigratisapp", },
            { nome: "cheff del Popolo", identificador: "chefe-em-casa", },
            { nome: "cheirinho de bebê", identificador: "cheirinhodebebe", },
            { nome: "Cheiro Meu Perfumes", identificador: "cheiromeuperfumes", },
            { nome: "Chloe Velas Aromáticas", identificador: "chloevelasaromaticas", },
            { nome: "Chopp do alemão", identificador: "choppdoalemao", },
            { nome: "Cia Carolir", identificador: "ciacarolir", },
            { nome: "CIA da Persiana", identificador: "ciadapersiana", },
            { nome: "Ciadocemel", identificador: "ciadocemel", },
            { nome: "C.I.J roupas e acessórios", identificador: "cijroupaseacessorios", },
            { nome: "Cintia Silva", identificador: "cintiasilva", },
            { nome: "CklingerieNF", identificador: "cklingerienf", },
            { nome: "Cleone Farias Corretor de Imóveis", identificador: "cleone-corretor", },
            { nome: "Click Designer72", identificador: "click-designer72", },
            { nome: "CLICK SHOPS", identificador: "lojaclickshops", },
            { nome: "Climar climatização e elétrica", identificador: "httpsclimarbelpanegociositemtrue", },
            { nome: "Climas Ar Condicionados", identificador: "climasarcondicionados", },
            { nome: "close confecções", identificador: "nadiaagner", },
            { nome: "Closet Pink Tricot", identificador: "closetpinktricot", },
            { nome: "CL Santini cosméticos", identificador: "santinicosmeticos", },
            { nome: "CLS palets", identificador: "clspalets", },
            { nome: "Clube do Açai e Sorvete", identificador: "clube-do-acai", },
            { nome: "Clube ds Costura", identificador: "clubedacostura", },
            { nome: "CNS-Carla", identificador: "brechodacarlacns", },
            { nome: "CN utilidades", identificador: "cn-utilidades", },
            { nome: "Coffee Drean Flavor", identificador: "drean-flavorcoffee", },
            { nome: "coisa boba", identificador: "httpsinstagramcomcoisabobautmmedium", },
            { nome: "Coisas da Aninha", identificador: "coisas-da-aninha", },
            { nome: "coisas da Claudia", identificador: "coisas-da-claudia", },
            { nome: "coleta e entregas express", identificador: "wwwcoletaentregasexpress", },
            { nome: "comercial", identificador: "comercial", },
            { nome: "Comic Con", identificador: "comicon", },
            { nome: "completa Moda Fashion", identificador: "completamodafashion", },
            { nome: "ConCon e Investimentos", identificador: "conconeassociados", },
            { nome: "confecçoes Souza", identificador: "confeccoes-souza", },
            { nome: "Confeitaria Fram Lima", identificador: "httpswamemessagegykisypjpjtel1", },
            { nome: "Confeitaria Fram Lima", identificador: "confeitariaframlima", },
            { nome: "confeiteira Ana lucia", identificador: "confeiteiraanalucia", },
            { nome: "Connectado TI", identificador: "connectadoinformatuca", },
            { nome: "Constancio Comércio de Bebidas", identificador: "cachacasconstancio", },
            { nome: "Constancy", identificador: "constancy", },
            { nome: "CONSTRUÇÕES BR", identificador: "construcoesbr", },
            { nome: "construções e reformas", identificador: "fellipelima22", },
            { nome: "Constru Norte", identificador: "construnortevarejoeatacado", },
            { nome: "CONSTRUSOUSA", identificador: "construsousa", },
            { nome: "construtaz", identificador: "construtaz", },
            { nome: "Construtora e Mercado Pinheiro", identificador: "pinheiro-2022", },
            { nome: "Construtora Horizontexangô", identificador: "httpsphotosappgoogl1neyjp5rugmwsfdy7", },
            { nome: "construtora irm", identificador: "55-41-8749-1682", },
            { nome: "CONSTRUTOR PASSOS MIZZOY", identificador: "cleidivaldomssgmailcom", },
            { nome: "Cooking", identificador: "cokcheiadesabor", },
            { nome: "costura criativa_Fabi", identificador: "costuracriativa-fabi", },
            { nome: "CQz Style", identificador: "httpapiwhatsappcomsendphone55229980", },
            { nome: "CR Camisaria e Acessorios", identificador: "crcamisariaacessorios", },
            { nome: "CREATIVE", identificador: "creative", },
            { nome: "crf elétrica", identificador: "httpsapiwhatsappcomsendphone5521964", },
            { nome: "Criarte Personalizados", identificador: "criartepersonalizados", },
            { nome: "Criatorio aves mimadas", identificador: "httpswwwfacebookcomgroups8495971424", },
            { nome: "Cris Du Corte", identificador: "chrisducorte2", },
            { nome: "CRIS MODA EVANGÉLICA", identificador: "https", },
            { nome: "Criss Moda Feminina", identificador: "crissmoda013", },
            { nome: "Cristal Modas", identificador: "cristalmodas", },
            { nome: "Cristina lanches", identificador: "cristina-lanches", },
            { nome: "Crochê fofura de fio", identificador: "crochefofuradefio", },
            { nome: "CR salgados", identificador: "crsalgadosgmailcom", },
            { nome: "Cruvinel Representações", identificador: "cruvinelrepresentacoes", },
            { nome: "ctrssmp", identificador: "ctrssmp", },
            { nome: "Curitiboca roupas", identificador: "curitiboca-roupas", },
            { nome: "Curso", identificador: "injetaveis-na-pratica", },
            { nome: "Cutelaria Primitiva", identificador: "cutelariaprimitiva", },
            { nome: "D & A Cactos e Suculentas", identificador: "deacactosesuculentas", },
            { nome: "Dádivas Locações e Eventos", identificador: "dadivas-locacoes", },
            { nome: "DaduImp", identificador: "takego", },
            { nome: "Daianny vendas", identificador: "daianny", },
            { nome: "daia Re", identificador: "wwwdaianesilvacom", },
            { nome: "Da Luz Multimarcas e Serviços", identificador: "daluz", },
            { nome: "DAMA DE AÇO", identificador: "damadeaco", },
            { nome: "Dan Fragrâncias e Cosméticos", identificador: "httpsdanfragranciasvendizapcom", },
            { nome: "Day coffer", identificador: "dayane29", },
            { nome: "Day criatividades em e.v.a", identificador: "httpsinstagramcomdaycriatividadesem", },
            { nome: "Day Doces", identificador: "daydoces", },
            { nome: "DC &CALADOS", identificador: "loja-meigratisapp", },
            { nome: "D'conservas", identificador: "judislenedacostaesouza", },
            { nome: "Débora Leite doces  finos", identificador: "deboraleitedocesfinos", },
            { nome: "debora modas", identificador: "deboramodasm21", },
            { nome: "Dega Leopoldina", identificador: "degaleopoldina", },
            { nome: "Delicato", identificador: "delicatocom", },
            { nome: "Delicia Divina", identificador: "deliciadivina", },
            { nome: "Delicias da casa", identificador: "deliciasdacasa", },
            { nome: "Delícias da Garagem", identificador: "deliciasdagaragem", },
            { nome: "Delicias da Nazinha", identificador: "deliciasdanazinha", },
            { nome: "Delicias da Pri", identificador: "delciasdapri", },
            { nome: "Delicias Da Rosyta", identificador: "deliciasdarosyta", },
            { nome: "Delicias da Thataa", identificador: "httpsinstagramcomdeliciasdatathaaut", },
            { nome: "Delivery Hortifruti", identificador: "deliveryhortifruti", },
            { nome: "Denise Farias", identificador: "denisefariascomercial", },
            { nome: "deposito kadov", identificador: "depositokadov", },
            { nome: "Dero Doceria Gourmet", identificador: "derodoceriagourmet", },
            { nome: "Desapegos da Deh", identificador: "desapegos", },
            { nome: "Desentupidora Freitas", identificador: "desentupidora", },
            { nome: "Deslumbre Sapataria", identificador: "deslumbresapataria", },
            { nome: "DESTAKAGORA ® CARROS DE SOM PROFISSIONAL", identificador: "destakagora", },
            { nome: "Destak Gesso", identificador: "destak-gesso", },
            { nome: "DFM cosméticos", identificador: "dfm-cosmeticos", },
            { nome: "dg peças", identificador: "pecasdecarro", },
            { nome: "Diamond Store", identificador: "newshoescalcados", },
            { nome: "Dias Artesanatos e &Cia", identificador: "lojadiasartesanatos", },
            { nome: "Digitec Tv", identificador: "digitectv", },
            { nome: "Di Máximo", identificador: "dimaximo", },
            { nome: "dinastia make", identificador: "dinastiamake", },
            { nome: "Dino Design", identificador: "dinodesign", },
            { nome: "Distribuidora das PATROAS", identificador: "pedidosdebebidas", },
            { nome: "Distribuidora Gela Mais", identificador: "distribuidoragelamais", },
            { nome: "distribuidora jr", identificador: "distribuidora-jr", },
            { nome: "distribuidor autorizado Southliss", identificador: "teosantoscabeleireiro", },
            { nome: "Divas Boutique", identificador: "divasboutique", },
            { nome: "Divino Armário", identificador: "divino-armario", },
            { nome: "Divino Estofados", identificador: "httpswwwinstagramcomreelczzbnbjjit5", },
            { nome: "Divinópolis turismo", identificador: "divinopolistransporteeturismogmailc", },
            { nome: "DLUCBIJUTERIAS", identificador: "dlucbijuterias-biju", },
            { nome: "dmay confecções", identificador: "lojameigratisapplojadmayconfecoes", },
            { nome: "Doce Dom", identificador: "httpswwwifoodcombrdeliveryguarulhos", },
            { nome: "Doce Encanto Bolsas e Acessórios", identificador: "httpsinstagramcomdoceencantorondonu", },
            { nome: "Doce Lírio confeitaria", identificador: "docelirioconfeitaria", },
            { nome: "DoceLis", identificador: "docelis", },
            { nome: "Doce Pecado", identificador: "docepecadost", },
            { nome: "Doceria MM", identificador: "doceria-mm", },
            { nome: "Doces & Cia", identificador: "httpschatwhatsappcomjxq9bbvxpmne1hy", },
            { nome: "doces da gigi", identificador: "docedagigi", },
            { nome: "Doces&Desejos", identificador: "httpswwwfacebookcomgroups1285264441554889refshare", },
            { nome: "doces Maria formiga", identificador: "httpswamemessageudt5o5wlanq6i1", },
            { nome: "Doces Sonhos", identificador: "docessonhos", },
            { nome: "docuradalela", identificador: "docuradaleila", },
            { nome: "Dogao Da Bell", identificador: "httpswwwinstagramcomtvcbni3cfjhekut", },
            { nome: "Dogão Diferenciad0", identificador: "dogaodiferenciad0", },
            { nome: "Dog Mania", identificador: "dogmaniaw3", },
            { nome: "Dogueria AUAU", identificador: "dogueriaauau", },
            { nome: "dona chica centro de estética animal", identificador: "donachicaesteticaanimal", },
            { nome: "Donas", identificador: "donas74rd", },
            { nome: "Dona Sika Boutique", identificador: "donasikaboutique", },
            { nome: "D'on Sabor Restaurante e lanchonete", identificador: "b1ebf92e-de9e-44dc-88b7-2cc154f748b", },
            { nome: "Donut's dos Sonhos", identificador: "donutsdosonhos", },
            { nome: "Drê Artes", identificador: "dre-artes-espalhe-amor-amo-cheiros", },
            { nome: "D.Sandra Pensão", identificador: "httpswamemessageiz4y3q4z5us5a1", },
            { nome: "DUAS IRMAS", identificador: "duasirmas", },
            { nome: "du gueto", identificador: "gagodogueto", },
            { nome: "Dumondy Assistencia Técnica", identificador: "dumondyassistencia", },
            { nome: "Dunamis Oficina Agrícola", identificador: "dunamisagromotiva", },
            { nome: "EBS REFORMAS E CONSTRUÇÕES", identificador: "ebsreformas", },
            { nome: "Eclow Comércio e Fabricação", identificador: "eclow", },
            { nome: "Ecobio Personalizados & Artesanais", identificador: "ecobiopersonalizados", },
            { nome: "ECRDgold - A Word of Possibilities", identificador: "ecrdgold", },
            { nome: "É Di AÇÚCAR", identificador: "ediacucarr", },
            { nome: "Edilane Modas e Variedades", identificador: "edilanemodas", },
            { nome: "EDINEIDE  cesta", identificador: "edineidefigueredoogmail", },
            { nome: "Edu construção", identificador: "educonstrucao", },
            { nome: "EfeEle Gráfica", identificador: "efeele-grafica", },
            { nome: "EHC contrução e acabamento", identificador: "emerson-pedreiro", },
            { nome: "EJM REFRIGERAÇÃO", identificador: "ejmrefrigeracaoejmrefrigeracaocoml", },
            { nome: "elburguerphb", identificador: "elburguerphb", },
            { nome: "ElectroCuba-JE", identificador: "electrocuba-je", },
            { nome: "Elenthe Climatização E Serviços Gerais", identificador: "elenthe-climatizao", },
            { nome: "Elétrica Express", identificador: "eletricaexpress", },
            { nome: "Elétric Soluction", identificador: "eletricsoluction", },
            { nome: "EletroInfor Costa Jr", identificador: "eletroinforcostajr", },
            { nome: "ELETRONICA ELETROFORT", identificador: "eletrofort", },
            { nome: "Eletrônica Século XXI", identificador: "eletronica-seculoxxcombr", },
            { nome: "ELITE ESTÉTICA AUTOMOTIVA", identificador: "elite-esttica-automotiva", },
            { nome: "elite System", identificador: "elitesystem7", },
            { nome: "Ellye Store", identificador: "ellyestore", },
            { nome: "Elma JC", identificador: "gomeschagas251gmailcom", },
            { nome: "EL SHADAY", identificador: "elshadayprodutosdehigieneelimpezas", },
            { nome: "Elyteles", identificador: "elyteles", },
            { nome: "Emerson Financias", identificador: "emersosantos456gmailcom", },
            { nome: "Emi baby", identificador: "emibaby", },
            { nome: "Emily vendas", identificador: "emilysantossgpgmailcom", },
            { nome: "E__MODAINTIMA", identificador: "emodaintima", },
            { nome: "Empire of iPhones", identificador: "fpradoimports", },
            { nome: "Empório Angry Bull", identificador: "emporioangrybull", },
            { nome: "EMPORIO BELL VITTA", identificador: "emporiobellvitta", },
            { nome: "Empório delícia doces & Cia", identificador: "httpswame5593984300997", },
            { nome: "Empório Mix Cosméticos, Acessórios e Chocolates", identificador: "emporiomix", },
            { nome: "Emporium da Charcutaria", identificador: "emporiumdacharcutaria", },
            { nome: "Empréstimo para todos", identificador: "agenteorinial", },
            { nome: "Entre 4Paredes sexshop", identificador: "entre4paredessexshop", },
            { nome: "ENTREGAS DIVINÓPOLIS E REGIÃO", identificador: "entregas-rpidas", },
            { nome: "equipedantas", identificador: "raeldantas26gmailcom", },
            { nome: "Erasmo Móveis", identificador: "erasmomoveis", },
            { nome: "Erika lima cuida da sua beleza", identificador: "erikalima8gmail", },
            { nome: "Esmaltaria Cristiana Souza", identificador: "esmaltariacristianasouza", },
            { nome: "Espaço Alynne Eloah", identificador: "alinneeloah", },
            { nome: "espaço cida silva", identificador: "espacocidasilva", },
            { nome: "Espaço JL Beleza", identificador: "espacojlbeleza", },
            { nome: "espaço lima fiamma", identificador: "httpsapiwhatsappcomsendphone5569992", },
            { nome: "espaço mulher", identificador: "base-bomba", },
            { nome: "espaço turquesa", identificador: "httpsinstagramcomespacoturquesaoficialutmmediumcopylink", },
            { nome: "Espagueteria Gourmet", identificador: "espagueteriagourmet", },
            { nome: "Espetinho 1010", identificador: "girlethysoaresgmailcom", },
            { nome: "E.S Polimentos Estetica", identificador: "espolimento", },
            { nome: "Essencial Material de Construção", identificador: "lojaessencialmaterialdeconstrucao", },
            { nome: "Essencial Shop!", identificador: "essencialshop", },
            { nome: "estofados de Paula", identificador: "estofadosdepaula", },
            { nome: "ESTOPAS CORREIA", identificador: "httpsestopas-correianegociositeutms", },
            { nome: "Etnias Capoeira", identificador: "etniascapoeira", },
            { nome: "Eu Tô Podendo", identificador: "eutopodendo", },
            { nome: "Evandro construção", identificador: "evandro-construo", },
            { nome: "Everton luis", identificador: "everton-luis", },
            { nome: "Everton pintura e reformas", identificador: "evertonpinturas", },
            { nome: "Evos Digital", identificador: "evosdigital", },
            { nome: "Exencial Calçados profissionais", identificador: "exencialcalcadosprofissionais", },
            { nome: "ezequias rialto Mendes", identificador: "ezequiasrialto", },
            { nome: "fabiano construções e reformas", identificador: "fabianoconstrucoes", },
            { nome: "Fabio cosmeticos", identificador: "fabiocosmeticos", },
            { nome: "fada Madrinha personalizados", identificador: "fadamadrinhapmw", },
            { nome: "família persa ( protetor de animais )", identificador: "meus-bebes-persas", },
            { nome: "Família Possamai", identificador: "familiapossamai", },
            { nome: "Fantasy Shop", identificador: "httpsshopeecombrshopfantasy", },
            { nome: "Farol Multimarcas", identificador: "farolmultimarcas", },
            { nome: "Fastcell Delivery", identificador: "httpsinstagramcomfastcelldeliveryut", },
            { nome: "Fátima cabeleireira", identificador: "cabeleireira-fatima", },
            { nome: "Fátima Flor Ateliê", identificador: "fatimaflormeigratis", },
            { nome: "fazer moto táxi", identificador: "45131611000107", },
            { nome: "FB AR Automotivo", identificador: "httpswamemessageotc5zfvvzegdl1", },
            { nome: "f cell", identificador: "fcell", },
            { nome: "Felipe tm", identificador: "felipetmilani", },
            { nome: "Feltro por mim Cris Piconi", identificador: "feltropormimcrispiconi", },
            { nome: "Feltros Ana Neves", identificador: "feltrosananeves", },
            { nome: "Fê Makeup & Acessórios", identificador: "femakeupacessorios", },
            { nome: "Fênix Suplementos, Outfit e acessórios esportivos", identificador: "fenixsuplementoseconfeccao", },
            { nome: "Fernando Instalador", identificador: "fernandoinstalador", },
            { nome: "ferragem Santos", identificador: "ferragem-santos", },
            { nome: "Ferreira Perfumaria & Cosmésticos o Boticário", identificador: "lojaferreiraperfumariaecosmesticosoboticario", },
            { nome: "financeira master", identificador: "financeiramaster193gmailcom", },
            { nome: "fjms construcoes me", identificador: "loja", },
            { nome: "FJ & PACHECOS MEI", identificador: "fjpachecosmei", },
            { nome: "fk distribuidora de bebidas", identificador: "fokusdistribuidoradebebidas", },
            { nome: "Flambas", identificador: "flambas", },
            { nome: "Fletech Soluções em TI", identificador: "fletechti", },
            { nome: "Flor D’Angélica", identificador: "flordangelica", },
            { nome: "Flor de Luz", identificador: "flordeluz", },
            { nome: "Flor de Salto Alto Store", identificador: "flordesaltoaltostore", },
            { nome: "Flor Santos Ateliê", identificador: "florsantosatelie", },
            { nome: "F.M. Doces e Salgados", identificador: "fernanda", },
            { nome: "foreign languages", identificador: "foreign-languages-mei-app", },
            { nome: "FortNet", identificador: "67", },
            { nome: "Fox mex", identificador: "foxmex", },
            { nome: "Frank Publicidade", identificador: "frankpublicidade", },
            { nome: "F-TRAINING", identificador: "ftrainingconsultoriaonline", },
            { nome: "Futshop", identificador: "futshop", },
            { nome: "Futures Brasil", identificador: "futures", },
            { nome: "G94 IMPORTADOS", identificador: "httpsinstagramcomg94importadosutmme", },
            { nome: "Gabi Doces & Store", identificador: "gabidocesestore", },
            { nome: "Gabriela Oliveira", identificador: "httpswwwfacebookcomgabrielaoliveira197", },
            { nome: "gabs kids", identificador: "gabskids", },
            { nome: "Garagem da Maria", identificador: "httpswwwfacebookcomgaragem-da-maria", },
            { nome: "Garota de Estilo", identificador: "garotadestilo", },
            { nome: "Garota Moderna", identificador: "garotamoderna", },
            { nome: "gd lanches", identificador: "gd-lanches", },
            { nome: "Gemma Design", identificador: "gemmadesign", },
            { nome: "Genox", identificador: "genox", },
            { nome: "Gessi CarlosGrego", identificador: "carlosgrego", },
            { nome: "Gesso D'ecor", identificador: "gessodecor", },
            { nome: "gesso e iluminação", identificador: "gessoeiluminacao", },
            { nome: "Giancarlo Colaço", identificador: "giancarlocolacoimoveis", },
            { nome: "Gigi & Gugu kids moda infantil", identificador: "httpswwwinstagramcomgigigugukids", },
            { nome: "Gil Refrigeração", identificador: "lhttpswamemessageytd4fe3kwhkdo1", },
            { nome: "Gil soares cake", identificador: "gilsoarescake", },
            { nome: "Giovani Rei das Máquinas", identificador: "giovani-rei-das-maquinas", },
            { nome: "Girassol Semijoias", identificador: "girassolsemijoias-10", },
            { nome: "Girassolstore", identificador: "girassolstore", },
            { nome: "Giselle Lima", identificador: "giselle", },
            { nome: "gladmaterias", identificador: "gladmateriais", },
            { nome: "glaucus guedes", identificador: "4231540188506891", },
            { nome: "Gloriapaiva", identificador: "minhalojagloriapaiva", },
            { nome: "GMendes gabs lentes", identificador: "httpsinstagramcomgabslentesutmmediu", },
            { nome: "Godoybarbercuts", identificador: "httpsbooksycompt-br170342godoybarbe", },
            { nome: "Góes transportes de executivos", identificador: "goes-transporte-de-executivos", },
            { nome: "Gomes moda evangélica", identificador: "gomes-moda-evanglica", },
            { nome: "Graciosa Moda Íntima e Feminina", identificador: "graciosamodaintimaefeminina", },
            { nome: "Gráfica Impre", identificador: "mei", },
            { nome: "Gráfica Styllo", identificador: "httpsshopeecombrgraficastyllo1802", },
            { nome: "Granolaria BioCereal", identificador: "granolabiocereal", },
            { nome: "grapyypintura", identificador: "grapyypintura", },
            { nome: "gringa modas", identificador: "gringamodas", },
            { nome: "G.R serviços", identificador: "989710100", },
            { nome: "GS empreendimentos", identificador: "gsempreender", },
            { nome: "GSOUZA", identificador: "mercadofacil", },
            { nome: "GS SERRALHERIA", identificador: "gsserralheria", },
            { nome: "Guê comercio de cosméticos", identificador: "ednilsonrepresenacoes", },
            { nome: "GUFESA BUSINESS", identificador: "gufesabusiness", },
            { nome: "Gugu lanches", identificador: "httpsliviafernandez716wixsitecomgug", },
            { nome: "Gui maria", identificador: "guimaria10", },
            { nome: "Gusmão Vidros", identificador: "vidrosgusmaorj", },
            { nome: "Gustavo e Gabriela Montadores", identificador: "montadores", },
            { nome: "HAJIMESHOP", identificador: "hajimeyou", },
            { nome: "Hambúrgueria Senhor Burguer", identificador: "httpswwwinstagramcominvitescontacti", },
            { nome: "HCA pinturas em geral", identificador: "helenoalves493gmailcom", },
            { nome: "Helena Cardoso Estofados", identificador: "helenacardosoestofados", },
            { nome: "Hellena stainner", identificador: "marykay", },
            { nome: "hidraulicaeletricapatos", identificador: "gmail", },
            { nome: "Hidroponicos e Naturais", identificador: "marcelo77", },
            { nome: "higienicar", identificador: "www-higienecar", },
            { nome: "Higor Tech Celulares e Acessórios", identificador: "higortechcell", },
            { nome: "HK Imports", identificador: "httpsinstagramcomhkimportsiubutmmed", },
            { nome: "H&L Cosméticos e Perfumaria ME", identificador: "hlrevendas", },
            { nome: "H&M", identificador: "janaynabernardohe", },
            { nome: "Ho Lokahi - Aromas Vibracionais", identificador: "aromasholokahi", },
            { nome: "Honorato LTDA", identificador: "honorato-store", },
            { nome: "House Pizzaria", identificador: "hp", },
            { nome: "HR", identificador: "hr", },
            { nome: "HRC", identificador: "hrconstru43gmailcom", },
            { nome: "H.S Refrigeração", identificador: "hsreftigeracao", },
            { nome: "Hud's Barbearia", identificador: "hudsbarbearia", },
            { nome: "HYPE", identificador: "hype", },
            { nome: "ideal bazar any", identificador: "aninhaadrianthauanygmailcom", },
            { nome: "ideal cosméticos", identificador: "idealcosmesticos", },
            { nome: "iggravatas", identificador: "iggravatass", },
            { nome: "ILHARTbyArlene", identificador: "ilhartbyarlene", },
            { nome: "imobiliária nação dos 318", identificador: "alvaro-germanobolcombr", },
            { nome: "imperio da lingerie", identificador: "httpschatwhatsappcombjsjfrda9wilwjlqegebsm", },
            { nome: "Império das Coxinhas", identificador: "impriodascoxinhas-crato", },
            { nome: "Império Fit", identificador: "bolachasimperiofit", },
            { nome: "Império Mateus", identificador: "imperiomateus", },
            { nome: "Império Tecnologia", identificador: "imperiotecnologia", },
            { nome: "Imperium Store", identificador: "imperium-store", },
            { nome: "ineis limpeza", identificador: "ineislimpeza", },
            { nome: "Infonetti informática", identificador: "infonettiinformatica", },
            { nome: "Infor.box", identificador: "inforbox", },
            { nome: "Ingridyimperio_damoda", identificador: "httpsshopeecombringridyimperiodamod", },
            { nome: "INSTALFRETES MONTAGENS", identificador: "instalfretesmontagens", },
            { nome: "Integral &Natu - Produtos Naturais", identificador: "integral-natu", },
            { nome: "Intuitiva Art", identificador: "intuitivaart", },
            { nome: "IP-Academia", identificador: "httpswwwinstagramcompcy4bndqj2s-utm", },
            { nome: "Irmãos à obra", identificador: "construir-irmaos-a-obra-reformas", },
            { nome: "Isaías móveis projetado", identificador: "httpswwwindicalocalcomisayas-moveis", },
            { nome: "Isa moda feminina", identificador: "isamodafeminina", },
            { nome: "ITALY PRATAS", identificador: "italypratas", },
            { nome: "Itamar Cidreira", identificador: "cidreira84", },
            { nome: "JA2L Retroescavadeira", identificador: "tamiresfernandes", },
            { nome: "Jack Doce Sabor", identificador: "jackcristina97gmailcom", },
            { nome: "JACKELINE CONFEITARIA", identificador: "jackelineconfeitaria", },
            { nome: "Jana cakes lanchonete e confeitaria", identificador: "janacakeslanchoneteeconfeitaria", },
            { nome: "Jana Segredos Dos Cosméticos", identificador: "httpsinstabioccjanasegredodoscosmet", },
            { nome: "JAPONEGA BEER", identificador: "japonega-beer", },
            { nome: "Jaquecomnatura", identificador: "httpswwwnaturacombrconsultoriajaque", },
            { nome: "Jardim das Flores", identificador: "amanda-plantas", },
            { nome: "jardinagem e serviços gerais", identificador: "jardinagemeservicosemgeral", },
            { nome: "JARRONES & POTES", identificador: "jarrones", },
            { nome: "J&A variedades", identificador: "jaqueline-araujo", },
            { nome: "JB comércios e produtos", identificador: "jbcomercios", },
            { nome: "JB Perfumaria", identificador: "httpsminhalojaboticariocombrperfuma", },
            { nome: "JB variedades", identificador: "jbvariedadesmaraba", },
            { nome: "JCAR REFRIGERAÇÃO", identificador: "jcar", },
            { nome: "Jc Inforcell", identificador: "jcinforcell", },
            { nome: "J.C. Serviços Pintura Geral", identificador: "jcpinturas", },
            { nome: "JDEcalçados", identificador: "jdecalcados", },
            { nome: "JEC", identificador: "joseane-gama", },
            { nome: "jeeletrica", identificador: "loja-jeeletrica-com", },
            { nome: "Jeh vendas", identificador: "jehvendas", },
            { nome: "Jeisy moda fitness", identificador: "jeisymodafitness", },
            { nome: "Jessica leite", identificador: "jessicaleite", },
            { nome: "Jéssica Semijoias", identificador: "jeehsemijoias", },
            { nome: "jheny bolos e doces", identificador: "jhenybolos", },
            { nome: "Jhone films", identificador: "jhone-films", },
            { nome: "Jhonnys MadruWalker", identificador: "madruwalker", },
            { nome: "Jigoku Dojo Escola de Artes Marciais", identificador: "jigokudojo", },
            { nome: "JJ BARROS", identificador: "jjbarrosdtscom", },
            { nome: "jjenxovais Ipatinga", identificador: "jjjenxovais", },
            { nome: "JJ vendas e serviço", identificador: "jjservicos", },
            { nome: "jlci serviços elétricos", identificador: "jlciservicoseletricos", },
            { nome: "JL EXPRESS", identificador: "jlexpress", },
            { nome: "JL gesso", identificador: "jlgesso", },
            { nome: "JL Lanches", identificador: "jllanches", },
            { nome: "JL Sushi Delivery", identificador: "jonhnessousa9416gmailcom", },
            { nome: "j.martinscomercioeservicos", identificador: "jmartinsservicos2021gmailcom", },
            { nome: "JM Chocolates", identificador: "jmchocolates", },
            { nome: "JME CLIMATIZAÇÃO E REFRIGERAÇÃO", identificador: "httpsinstagramcomjmeclimatizacaoger", },
            { nome: "J&M Lanches", identificador: "jmlanches", },
            { nome: "JM Personalizados", identificador: "jm-personalizados", },
            { nome: "Jms Entregas", identificador: "httpswamemessagenbrfzwuvvggik1", },
            { nome: "Jn", identificador: "naldosilvacom", },
            { nome: "JN.CONSTRUÇÃO EM GERAL", identificador: "joao30jardimhotmail", },
            { nome: "J&N jardinagem", identificador: "jonas-jardinagem", },
            { nome: "Joane Adesivos", identificador: "joaneadesivos", },
            { nome: "jo Artes", identificador: "josimaradasilva", },
            { nome: "John outlet", identificador: "john-outlet", },
            { nome: "jóia Rara", identificador: "joiararaacessorios", },
            { nome: "joias e semi joias", identificador: "caline", },
            { nome: "Jonathas-reformas em geral, da base ao acabamento", identificador: "httpslojareformasjonathascombr", },
            { nome: "Joyce acessórios", identificador: "joyceacessorios1953", },
            { nome: "J&P COSMÉTICOS", identificador: "lojajepcosmeticos", },
            { nome: "jp restaurantes", identificador: "joaopauloaparecido3790gmailcom", },
            { nome: "JRA ALIMENTOS", identificador: "jraalimentos", },
            { nome: "J&R Açaí", identificador: "jraacai", },
            { nome: "jr focaliza  rastreamentos veicular", identificador: "jrfocalozarastreamento", },
            { nome: "J & R instalações elétricas e hidráulicas", identificador: "jjerintalacoes", },
            { nome: "Jr Jardinagem", identificador: "jrjardinagemepiscina", },
            { nome: "JS ASSISTÊNCIA TÉCNICA E ACESSÓRIOS", identificador: "jsassistenciatecncia", },
            { nome: "jsd variedades", identificador: "jsdvariedades", },
            { nome: "JS ELETRÔNICOS E VARIEDADES", identificador: "js-eletronicos-e-variedades", },
            { nome: "J&S móveis", identificador: "moveis", },
            { nome: "Juca reparo em máquina agrícola", identificador: "jumoraism88gmailcom", },
            { nome: "Jucelia Vecchi Beauty", identificador: "vecchi", },
            { nome: "Ju crochê", identificador: "ju-croche", },
            { nome: "Jully Personalizados", identificador: "jullypersonaepresentes", },
            { nome: "Juninho Brown Montagens", identificador: "juninhobrownmontagens", },
            { nome: "Junior bracale designer em madeiras", identificador: "juniorbracale", },
            { nome: "JV gráfica e personalizados", identificador: "jvpersonalizados", },
            { nome: "JV SALGADO INDSUL FILM", identificador: "cesaasalgado", },
            { nome: "JVS express", identificador: "jvsexpress", },
            { nome: "Jx transportes", identificador: "jxtransportadoraltda", },
            { nome: "Kaju Cell", identificador: "kajucell", },
            { nome: "Kakau Crocheteria", identificador: "kakau-crocheteria", },
            { nome: "kamik", identificador: "kamikolegario", },
            { nome: "Karla vendas online", identificador: "httpswamemessagea26bhzsksa32i1", },
            { nome: "kaseirinha Fit", identificador: "httpsinstagramcomkaseirinhafitutmme", },
            { nome: "Katiane", identificador: "katianetenorio", },
            { nome: "Katlyn Laber espaço da beleza", identificador: "kaatyhlaberdias", },
            { nome: "Kellen Freitas nail designer", identificador: "kfnaildesigner1", },
            { nome: "KEMPER PRODUÇÃO", identificador: "djkemper", },
            { nome: "KF arquitetura e Interiores", identificador: "kfarquitetuta", },
            { nome: "KF modas.", identificador: "kf-modas", },
            { nome: "Kika Kids", identificador: "kika-kids", },
            { nome: "K&K Enxovais", identificador: "httpskarinethiememercadoshopscombr", },
            { nome: "K&L SHOES", identificador: "kelhhoes", },
            { nome: "Km gourmet's", identificador: "kmgourmetts", },
            { nome: "KN ACESSÓRIOS PARA CONTRUÇÃO", identificador: "knacessoriosparaconstrucao", },
            { nome: "kor e Pontos", identificador: "korepontos", },
            { nome: "Kza acabamento interiores", identificador: "kzaacabamentointeriores", },
            { nome: "Laabs prestadora de serviços", identificador: "eduardo-laabs", },
            { nome: "Lá Belle Perfumaria e Cosméticos", identificador: "labelleperfumaria", },
            { nome: "lacinhos", identificador: "lacinhos-ma", },
            { nome: "La Doceria da Jana", identificador: "ladoceriadajana", },
            { nome: "Lafrê boutique", identificador: "lafreboutique", },
            { nome: "Laisa Melo Studio", identificador: "laisamelostudo", },
            { nome: "Lala combos", identificador: "lalacomboss", },
            { nome: "LA lava car", identificador: "obrigado-pela-preferncia", },
            { nome: "Lá Mariah roupas e acessórios", identificador: "lamariah2029", },
            { nome: "Lanches da Su", identificador: "lanchesdasu", },
            { nome: "lanches Michelly", identificador: "lanchesmichellygmailcom", },
            { nome: "lanches pimentarosa", identificador: "lanches-pimentarosa", },
            { nome: "Lanchonete da Bru", identificador: "lanchonetebru", },
            { nome: "lanchonete Lb Pastelaria", identificador: "meigratisapp", },
            { nome: "lanchonete Sarah Burguer", identificador: "httpsinstagramcomlanchonetesarahbur", },
            { nome: "Landas", identificador: "nicolete", },
            { nome: "LANDYAMODAS", identificador: "landyamodas", },
            { nome: "Lane das jantas", identificador: "lanedasjantas", },
            { nome: "LaneStore", identificador: "lanestore", },
            { nome: "laticínio Alvorada", identificador: "laticnioalvorada", },
            { nome: "Laura Storre", identificador: "httpsapiwhatsappcomsendphone5544997336313", },
            { nome: "laurefotografias", identificador: "laurefotografias", },
            { nome: "Lay fotografia mídia e entretenimentos", identificador: "laianyfotografia", },
            { nome: "LD delicias lanches", identificador: "httpsinstagramcomdallilaamedeiros26", },
            { nome: "L&D Delivery e Festas", identificador: "led-deliveryefesta", },
            { nome: "Leão Lanches", identificador: "leaolanches", },
            { nome: "LeB decorações", identificador: "lebdecoracoes2021", },
            { nome: "leidy acessorios", identificador: "leidyacessorios", },
            { nome: "Leilissíma saúde beleza", identificador: "leilissima-esteticistasaudebeleza", },
            { nome: "lê lava car", identificador: "wwwlelava", },
            { nome: "lencois tourism", identificador: "lencois-tourism", },
            { nome: "leocell Tec", identificador: "leocell-tec", },
            { nome: "leonardorickmodas", identificador: "leonardorickmodas", },
            { nome: "Les Mora Store", identificador: "lesmorastore", },
            { nome: "Letícia Modas", identificador: "leticiamodas", },
            { nome: "Levi mercadinho", identificador: "majorferreira", },
            { nome: "LF construções", identificador: "construes", },
            { nome: "LF INSTALAÇÕES E MANUTENÇÃO", identificador: "lf-instalacao", },
            { nome: "LG IMPORT'S MULTIMÍDIA 🖥️💻", identificador: "ligeirinhoimportsevariedades", },
            { nome: "LGMSERVCAR", identificador: "lgmservcar", },
            { nome: "LH7 STORE", identificador: "lh7store", },
            { nome: "Ligue ja telas de proteção", identificador: "lojatelas", },
            { nome: "Lila Flores Decor", identificador: "floresdecor", },
            { nome: "Lilian Thales", identificador: "lilian-thales-123", },
            { nome: "Lili pratas", identificador: "lilipratas", },
            { nome: "Limeira Store", identificador: "limeirastore", },
            { nome: "Lincoln Vendas e Negócios", identificador: "vendasenegocios", },
            { nome: "Livraria Evangelica Cavalcante", identificador: "livrariaevangelicacavalcante", },
            { nome: "Liz Doces Gourmet", identificador: "lizgourmetdoce12", },
            { nome: "LK Lanches", identificador: "lklanches", },
            { nome: "LLORENTE AÇOS", identificador: "llorenteacos", },
            { nome: "L&L Paparicos de Papel", identificador: "paparicosdepapelrr", },
            { nome: "lobato motos", identificador: "lobatomotos", },
            { nome: "lojA Avon", identificador: "https-loja-avon", },
            { nome: "Loja colorida", identificador: "loja-colorida", },
            { nome: "Loja da Bru", identificador: "bru-lojaa", },
            { nome: "Loja da Laís", identificador: "lojadalais", },
            { nome: "loja do Angelo", identificador: "loja-meigratisapploja", },
            { nome: "loja do diogo", identificador: "loja-do-diogo", },
            { nome: "Loja Lira Store", identificador: "lojalirastoreoficial", },
            { nome: "loja LNOVO Eletrônico", identificador: "lnovoeletronico", },
            { nome: "Loja Mania", identificador: "maniadeluxus", },
            { nome: "lojao maya", identificador: "lojao-maya", },
            { nome: "@Loja Safira", identificador: "loja-safira-meigratis-34", },
            { nome: "Lojas do Baiano Rogério Saraiva", identificador: "loja-do-baiano-rogerio", },
            { nome: "loja virtual Jhon", identificador: "jnthnsilva", },
            { nome: "Lojinha da Dy", identificador: "lojinhadady", },
            { nome: "Lojinha da Sil", identificador: "lojinhadasil2016", },
            { nome: "lollita store", identificador: "httpsshopeecombrlollitastoreesmtt00", },
            { nome: "Lopes transportes", identificador: "italobruno", },
            { nome: "Lordão transporte de cargas e passageiros", identificador: "transporte-cargas-e-passageiros", },
            { nome: "Lostparadisebr", identificador: "lostparadisebr", },
            { nome: "lottie store", identificador: "loja-lottie", },
            { nome: "Lourenço", identificador: "lourenoco", },
            { nome: "Lovely Glam", identificador: "lovelyglamrj", },
            { nome: "LR Bebidas", identificador: "lrbebidas2021", },
            { nome: "LR SERVIÇOS E PUBLICIDADE", identificador: "laridoviki", },
            { nome: "l.s.c Prestadora de serviços", identificador: "httpswamemessagerc4qrnkc3kwrf1", },
            { nome: "lsespetaria", identificador: "httpswwwinstagramcomlsespetaria", },
            { nome: "LS MULTIMARCAS", identificador: "lsmultimarcasrj", },
            { nome: "luann's Barber Shop sua barbearia", identificador: "httpswamemessagemc2xuzav5mbia1", },
            { nome: "Lucas Leão Fotografia", identificador: "lucasfhotos21", },
            { nome: "Luca Store", identificador: "lucastoreonline", },
            { nome: "Lucia Oliverr&Wagner Wagão", identificador: "luciaoliverwagnerwagao", },
            { nome: "Lugon Eventos Musicais", identificador: "lugoneventosmusicais", },
            { nome: "luha confeccao", identificador: "luha", },
            { nome: "Luiza salgados", identificador: "luiza-salgados-ltda", },
            { nome: "Luiz Envelopamento", identificador: "luizenvelopamento", },
            { nome: "Luiz Salgados", identificador: "luizsalgados", },
            { nome: "Lu modas", identificador: "lumodas", },
            { nome: "LUNA UTILIDADES", identificador: "luziaramires", },
            { nome: "lu Ribeiro", identificador: "lu-ribeiro-designer-de-unhas", },
            { nome: "LYa Cosméticos", identificador: "lyacosmeticos22", },
            { nome: "m", identificador: "ana", },
            { nome: "MAAH CHINELOS PERSONALIZADOS", identificador: "maahchinelos", },
            { nome: "M.A ALUMÍNIO", identificador: "httpsshopeecombrmarcosantoniomarcul", },
            { nome: "Macarron Chef", identificador: "macarronchef22", },
            { nome: "MACEDO ELÉTRICA", identificador: "macedo-eletrica", },
            { nome: "machines House", identificador: "machines-house", },
            { nome: "Madame Panthera", identificador: "madamepanthera", },
            { nome: "MADAME VIRTUOSA MODAS", identificador: "madamevirtuosamodas", },
            { nome: "MADU RESTAURANTE", identificador: "httpsmadurestaurante5wixsitecomwebs", },
            { nome: "Mãe Home Office Papelaria Digital", identificador: "papelaria-maehomeoffice", },
            { nome: "Magali pastéis", identificador: "magali-pasteis", },
            { nome: "Magari", identificador: "magari", },
            { nome: "magnus construção", identificador: "vagnermagnus80gmail", },
            { nome: "Mahravilhosa Store- Loja De Presentes", identificador: "mahravilhosastorelojadepresentes", },
            { nome: "MAIA CHAVEIRO", identificador: "maiachaveiro", },
            { nome: "Makeup Bennett", identificador: "makeupbennett", },
            { nome: "M.A Lanches", identificador: "malanches", },
            { nome: "Malu perfumes", identificador: "chega-masmalu", },
            { nome: "manahsabor", identificador: "manasabor", },
            { nome: "Mangata Jeans", identificador: "mangata-jeans", },
            { nome: "Mäntels", identificador: "mantels", },
            { nome: "Manu.moda", identificador: "loja-fisica", },
            { nome: "Máquininha de cartão", identificador: "evertonrevendedorautorizado", },
            { nome: "Maragogi turismo", identificador: "jeancarlosturismoecologicogmailcom", },
            { nome: "Marcenaria santos", identificador: "marcenaria-santos", },
            { nome: "Maria Helena Havaianas Decoradas", identificador: "mariahelenahavainasdecoradas", },
            { nome: "mariah Modas", identificador: "mariahmodas", },
            { nome: "Maria Manuela perfumes", identificador: "mariamanuelaperfumes", },
            { nome: "marinho piscina", identificador: "lojamarinhopiscina", },
            { nome: "MaristonReis Serviços", identificador: "maristonreisservicos", },
            { nome: "Marmoraria NobreArt", identificador: "httpswwwfacebookcommarmorarianobrea", },
            { nome: "Marta Botelho Podóloga", identificador: "martabotelhopodologa", },
            { nome: "Martins acessórios", identificador: "martins-acessrios", },
            { nome: "Marylumakes", identificador: "httpswame5562983209929textolc3a1sej", },
            { nome: "MaryNiel Copiadora e Brindes", identificador: "maryniel-copiadora", },
            { nome: "matriz", identificador: "matriz-refrigerao", },
            { nome: "MAXELTEK", identificador: "maxeltek", },
            { nome: "Mayara chocolates Ltda", identificador: "magoncalves984gmailcom", },
            { nome: "May Delicias no Pote", identificador: "maydeliciasnopote", },
            { nome: "Mayra", identificador: "jmautomacao", },
            { nome: "Mc Computadores Assistência Técnica", identificador: "httpsgpagercz5rh3zoe7toeae", },
            { nome: "M C M Variedades", identificador: "httpsshopeecombrmarinetecamargosmtt", },
            { nome: "MDM CLIMATIZAÇÃO", identificador: "mdmclimatizacao", },
            { nome: "Mecânica Big Big", identificador: "bigbig", },
            { nome: "mecânica hidráulica", identificador: "mecanica-hidrulica", },
            { nome: "MEGA BATATA 100", identificador: "megabatata100", },
            { nome: "mel lanches", identificador: "mel-lanchea", },
            { nome: "menina sapeca", identificador: "menina-sapeca-chocolate", },
            { nome: "mercadinho e eletrônico jp", identificador: "jpcomcelulareshotmailcom", },
            { nome: "MERCADINHO I.N.E VARIEDADES & DIGITAL", identificador: "mercadinhoinevariedades", },
            { nome: "Mercadinho SV", identificador: "httpswamemessagexiuv3nwzuh3gl1", },
            { nome: "mercado Clímaco", identificador: "claudio6270gmailcom", },
            { nome: "Mercado Favo Rosilane", identificador: "httpsbrmercadofavocomrosilane", },
            { nome: "mercado moriah", identificador: "mercadomoria", },
            { nome: "mercearia treze de abril", identificador: "santo", },
            { nome: "meusmartphone.com", identificador: "lojameusmartphone", },
            { nome: "MF Joias Finas", identificador: "mfjoiasfinas", },
            { nome: "M&G Inforcell", identificador: "meginforcell", },
            { nome: "mgparques", identificador: "amanda-mgparques", },
            { nome: "MGS serviços gerais", identificador: "httpswwwfacebookcomrafapereiramg010", },
            { nome: "MI94", identificador: "lojami94", },
            { nome: "Mian cosméticos", identificador: "mian-entregas-e-cosmeticos", },
            { nome: "mimos ofertas", identificador: "mimosofertas", },
            { nome: "Minimale Bikinis", identificador: "minimalebikinis", },
            { nome: "MINIMERCADO BOM E BARATO", identificador: "minimercado-bom-e-barato", },
            { nome: "Mira Eletronica", identificador: "miraeletronica", },
            { nome: "mix bolsa se sandalias", identificador: "mixbolsasesandalias", },
            { nome: "mix conveniência", identificador: "mix-convenincia", },
            { nome: "MkBoss Menswear", identificador: "mkboss", },
            { nome: "M.L MULTIMARCAS", identificador: "httpsinstagramcommlmultimarcas01utm", },
            { nome: "m-moveis", identificador: "m-moveis", },
            { nome: "MM SHOP", identificador: "mm-shop-oficial", },
            { nome: "moda cosméticos", identificador: "abonita", },
            { nome: "Momentos", identificador: "momentos", },
            { nome: "MONTEIRO DIVERSÕES LTDA", identificador: "monteirodiversoes", },
            { nome: "Monteiros Publicidade", identificador: "monteirospublicidade", },
            { nome: "Morena Churros", identificador: "morenachurros", },
            { nome: "morena flor delivery", identificador: "morenaflordelivery", },
            { nome: "MOTO TÁXI PIRAJUÍ", identificador: "mototaxipirajui", },
            { nome: "M&P Present", identificador: "mplima0467gmailcom", },
            { nome: "mr ASSISTÊNCIA TÉCNICA E DISTRIBUIDORA DE PEÇAS", identificador: "fabinhoalvez09gmailcom", },
            { nome: "Mr. Eventos Gourmet", identificador: "mrdindingourmet", },
            { nome: "MR personalizados", identificador: "mrpersonalizados90gmailcom", },
            { nome: "Mr. Sattor", identificador: "mistersattor", },
            { nome: "MS - Artigos em Couro", identificador: "msartigosemcouro", },
            { nome: "MT pneus", identificador: "mtpneus", },
            { nome: "MULTIMAIS VARIEDADES", identificador: "multimaisvariedades", },
            { nome: "Mundo Baby", identificador: "mundobaby", },
            { nome: "Mundo dos Textos", identificador: "mundodostextos", },
            { nome: "Mundo mágico festas ( locações)", identificador: "httpswame556291572365", },
            { nome: "Mundo Prata", identificador: "mundoprata", },
            { nome: "MURILLO PLOTTER", identificador: "plottermurillo", },
            { nome: "MV POWER FAST", identificador: "mvpowerfast", },
            { nome: "myfriendsstore", identificador: "myfriendsstore", },
            { nome: "Mykael Jackson", identificador: "mykaeljacksonhairterapy", },
            { nome: "Na Beka", identificador: "nabeka", },
            { nome: "Natech assistência técnica especializada 📱🍎🤖", identificador: "natech", },
            { nome: "nati do kit", identificador: "alecsandromartinhomartinhogmailcom", },
            { nome: "nattshirts", identificador: "nattshirts", },
            { nome: "NB Informática", identificador: "nbinformatica", },
            { nome: "negretto imports", identificador: "negrettoimports", },
            { nome: "Néia crochê e costura", identificador: "lu-croche", },
            { nome: "Néia moda fashion", identificador: "espaco-mulher", },
            { nome: "Nerdices&Mais", identificador: "nerdicesemais", },
            { nome: "netalingerie e biquínis", identificador: "netalingerie", },
            { nome: "New Ônix", identificador: "newonix", },
            { nome: "Nloja.com", identificador: "hobnegonline", },
            { nome: "NN MODA MG", identificador: "nnmodamg-bhminas", },
            { nome: "Nome da loja", identificador: "exemplo", },
            { nome: "Norte Construções Civis", identificador: "norteconstrucoescivis", },
            { nome: "nortsul moveis", identificador: "charlesfcs222gmailcom", },
            { nome: "Nosso Frango", identificador: "nossofrango", },
            { nome: "Nosso Quintal", identificador: "nossoquintalrio", },
            { nome: "NOVA ERA CROCHÊ", identificador: "novaeracroche", },
            { nome: "Nova Ideia Laser", identificador: "novaideialaser", },
            { nome: "Nova loja 1", identificador: "nova-loja", },
            { nome: "NOZES PECA", identificador: "httpswwwfacebookcomnozespecan3", },
            { nome: "NT Informática", identificador: "ntinformatica", },
            { nome: "Nutre Suplementos", identificador: "nutresuplementos", },
            { nome: "Nutrir Amor", identificador: "nutriramor", },
            { nome: "Oficina de Costuras", identificador: "oficinadecosturas3", },
            { nome: "Oficina Delivery", identificador: "jeancarlos1082", },
            { nome: "OFICINA RAÇA MOTORES", identificador: "oficinaracamotores", },
            { nome: "Oliveira insumos e serviços", identificador: "oliveirastyle", },
            { nome: "Oliveira Tintas & Pintura", identificador: "httpslinkmebiooliveiratintasepintur", },
            { nome: "o ponto da solda", identificador: "magnaldoferreira", },
            { nome: "Os Parças Barbershop", identificador: "osparcabarbershop", },
            { nome: "Ótica Solis", identificador: "oticasolis", },
            { nome: "Paçaí", identificador: "pacaifg", },
            { nome: "PADARIA E DISTRIBUIDORA DU PORTO", identificador: "amadeu-junior-mayo", },
            { nome: "PAGÉ ELETRÔNICOS", identificador: "pageeletronicos", },
            { nome: "Pai&Filho Construções", identificador: "paiefilhoconstrucoes", },
            { nome: "Paixão pela make", identificador: "paixaopelamake", },
            { nome: "Pão de Casa", identificador: "paodecasa", },
            { nome: "PÃO NA CHAPA", identificador: "pnc", },
            { nome: "Papel & Você Papelaria e presentes", identificador: "papelevoce", },
            { nome: "paperartsstore01", identificador: "paperartsstore01", },
            { nome: "Paper House Personalizados", identificador: "paperhouse", },
            { nome: "paradinhas do surf", identificador: "paradinhasdosurf", },
            { nome: "Paraíso das linhas e Acessórios", identificador: "ritacassia", },
            { nome: "PARFUM LRN REPRESENTAÇÕES", identificador: "parfumlrn", },
            { nome: "Paristec coifas e fogões", identificador: "lojameigratisapp", },
            { nome: "Parnatek", identificador: "parnatek", },
            { nome: "Patrícia Pires Cosméticos & Perfumaria", identificador: "patriciapires-cosmeticoseperfumaria", },
            { nome: "Pauliane Perfumaria e Cosméticos", identificador: "paullyannemarya", },
            { nome: "pauloconsertos", identificador: "pauloconsertos", },
            { nome: "PAY NEGÓCIOS", identificador: "patriciacorrea", },
            { nome: "PEBAS MONTAGEM", identificador: "httpswwwfacebookcomprofilephpid1000", },
            { nome: "P E K E R R U X O S", identificador: "pekerruxosjp", },
            { nome: "Pele Vip Estetic", identificador: "pelevip", },
            { nome: "PENBAR", identificador: "penbar", },
            { nome: "PequeLuxo", identificador: "httpsinstagramcompequeluxojputmmedi", },
            { nome: "Personalização A & D Stamps", identificador: "personalizacaoadstamps", },
            { nome: "Personallizee", identificador: "personallizee", },
            { nome: "Peterson Cell", identificador: "petersonjp28", },
            { nome: "pethy esperança", identificador: "pethyesperanca", },
            { nome: "Petiscaria do Raí", identificador: "petiscariadorai", },
            { nome: "petiscaria e bar altas horas i..j", identificador: "petiscaria-e-bar", },
            { nome: "PETSHOP PETPREMIUM", identificador: "petshoppetpremium", },
            { nome: "PETUBA REMOÇÕES", identificador: "petubaremocoes", },
            { nome: "PHG DOCES", identificador: "phgdoces", },
            { nome: "Pintor Alexander Hernandez Velasquez", identificador: "pintoralexanderhernandezfoz", },
            { nome: "PIRES REFRIGERAÇÃO", identificador: "piresrefrigeracao", },
            { nome: "Piscicultura Faust", identificador: "pisciculturafaust", },
            { nome: "Piscina&Serviços", identificador: "piscinaeservicos", },
            { nome: "pitoco cell", identificador: "httpscuttlyiowd53w", },
            { nome: "Pizzaria", identificador: "pizzaria", },
            { nome: "pizzaria Malagueta", identificador: "pizzariamalaguetasnp", },
            { nome: "PJ Auto Peças", identificador: "pjpecasgmailcom", },
            { nome: "planeta Multimarcas", identificador: "planeta-multimarcas", },
            { nome: "plástica dos pés", identificador: "rogeriamunoes", },
            { nome: "Poá Exnã_Artesanatos Indígena", identificador: "httpsvitrineelo7combrpoaexnaartesan", },
            { nome: "ponto da cocada", identificador: "ponto-da-cocada", },
            { nome: "ponto da moda", identificador: "vamodagmailcom", },
            { nome: "Ponto do Acarajé", identificador: "httpsinstagramcomacarajeponto22utmm", },
            { nome: "pontual serviços", identificador: "thiagosilvag2", },
            { nome: "Portuga Express", identificador: "portugaexpress", },
            { nome: "Pousada Canção Do Rio", identificador: "pousadacancaodorio", },
            { nome: "POWER-SAT", identificador: "wwwpowersat", },
            { nome: "PRAT-K", identificador: "pratksolucoes", },
            { nome: "Praxedes tortas e salgados", identificador: "jackysantoss81gmailcom", },
            { nome: "Prevenir", identificador: "prevenirepigmailcom", },
            { nome: "Pri Artesanato e Biju", identificador: "pri-artesanato-biju", },
            { nome: "PRIMUZ JOIAS", identificador: "httpsdrivegooglecomfiled1tcm3rll3rs", },
            { nome: "Priscila eventos", identificador: "priscila", },
            { nome: "Priscila Resinas", identificador: "priscila-resinas", },
            { nome: "PrJean.imoveis", identificador: "prjean", },
            { nome: "produtos da Bianca", identificador: "httpsconsultoramodabiancarode74f4b0", },
            { nome: "👇PRODUTOS INCRIVEIS👇", identificador: "divine", },
            { nome: "Projetando móveis planejados", identificador: "planejados-8698", },
            { nome: "Promdiv", identificador: "promdiv", },
            { nome: "PTO SEGUROS", identificador: "ptoseguros", },
            { nome: "quality salgados", identificador: "httpsquality-salgadosgoomerapp", },
            { nome: "QUEENS STORE", identificador: "httpsapiwhatsappcommessagejrm5hksaz", },
            { nome: "Que Seja Doce", identificador: "httpsinstagramcomdocesth30utmmedium", },
            { nome: "Radical produtos naturais", identificador: "radicalprodutosnaturais", },
            { nome: "Raduno", identificador: "frutassecas", },
            { nome: "Rafa modas", identificador: "rafaellima4601gmailcom", },
            { nome: "rafaoliveiratp", identificador: "rafaoliveiratp", },
            { nome: "Raio de Luz Crochê", identificador: "atelieraiodeluz", },
            { nome: "RALAUPARTS", identificador: "httpsmpagola2diywbq", },
            { nome: "Rancho'S Beer", identificador: "httpswwwinstagramcomranchosbeer", },
            { nome: "Rápido Wan", identificador: "rapidowan", },
            { nome: "rasta pinturas", identificador: "rasta-pinturascom", },
            { nome: "rasteirinhas da drika", identificador: "httpswamemessagelxsgjgi6xb7ln1", },
            { nome: "Rayanna Santos", identificador: "rayannasantosmf", },
            { nome: "RBS Múltiplos Negócios", identificador: "rbsmultiplosnegocios", },
            { nome: "RB VARIEDADES", identificador: "rbvariedades", },
            { nome: "RC Alimentos", identificador: "rcalimentos", },
            { nome: "R&C Design em Gesso", identificador: "designemgessorc", },
            { nome: "Rc&modas evangelica", identificador: "modasevangelica", },
            { nome: "Real Gesso", identificador: "realgesso", },
            { nome: "Real sabor", identificador: "realsabor", },
            { nome: "Recanto das quentinhas", identificador: "recantodasquentinhas", },
            { nome: "Receituário Gastronômico", identificador: "receituariogastronomico", },
            { nome: "RECUP Reciclagem de Termoplásticos - Grupo JP Soluções", identificador: "reciclagem-recup", },
            { nome: "reformas e construçães", identificador: "reformas-e-construes", },
            { nome: "Reformas e Pinturas em geral", identificador: "brazjaime9gmail-com", },
            { nome: "Reims", identificador: "reims", },
            { nome: "Reis", identificador: "reis", },
            { nome: "reiventesse", identificador: "reiventesse", },
            { nome: "Renata Shoes Lovers", identificador: "renatashoes", },
            { nome: "representante new skin", identificador: "contato", },
            { nome: "Restaurante e lanche vitoria", identificador: "restaurante-lanche-vitoria", },
            { nome: "Restaurante e Lanchonete Sabor Brasil", identificador: "brasilsabor", },
            { nome: "Restaurante Ki Delicia", identificador: "restaurantekidelicia", },
            { nome: "Restaurante O  Paulo", identificador: "paulooricardo16", },
            { nome: "Restaurante Pizzaria Divino Pratinho", identificador: "divino-pratinho", },
            { nome: "REVENDEDORA PAI E FILHO", identificador: "revendedora-pai-e-filho", },
            { nome: "RG montador de móveis profissional", identificador: "rgmontadordemoveis", },
            { nome: "Ricarte Modas", identificador: "ricartemodaspb", },
            { nome: "Rieger Pinturas", identificador: "riegerpinturas", },
            { nome: "rk contrução", identificador: "reconstruir-rk", },
            { nome: "R&k estofados & capotaria", identificador: "rkestofados", },
            { nome: "R&L Licores Artesanais", identificador: "licoresartesanais", },
            { nome: "R & L salgados e hambúrguer", identificador: "rlsalgadosehamburgueres", },
            { nome: "Rm instalações e manutenção de combate a incêndio", identificador: "rmincendio2013hotmailcombr", },
            { nome: "RM MANUTENÇÃO", identificador: "rmmanutencao", },
            { nome: "RM Papelaria & Acessórios", identificador: "rmpapelaria", },
            { nome: "RMS TRANSPORTES executivo", identificador: "httpswwwinstagramcompczo9yepudxiutm", },
            { nome: "RN Lanches e Variedades", identificador: "httpswamemessagej43qqhugwcfcl1", },
            { nome: "Rô Car Pneus Remold Vipal", identificador: "est-sim-amigo-se-preferir-fala-no-z", },
            { nome: "R.O descartáveis", identificador: "renatooliveira36", },
            { nome: "R.O Descartáveis", identificador: "rodescartaveis", },
            { nome: "Roma shop", identificador: "roma", },
            { nome: "RoniEricson", identificador: "cryptonita", },
            { nome: "Rosa lingerie", identificador: "rosalingerie", },
            { nome: "rose vassouras", identificador: "rosevassouras", },
            { nome: "ROTASEGUR ALARMES", identificador: "rotasegur-alarmes", },
            { nome: "ROTA STORE", identificador: "rotatracadas", },
            { nome: "R.P.O SERVIÇOS", identificador: "rpo", },
            { nome: "Rua do Fogo Bebidas", identificador: "rdfbebidas", },
            { nome: "Ruby Princess Shop", identificador: "httpsinstagramcomrubya96igshid1esd0xe6ro4bx", },
            { nome: "Rudyvez Gourmet", identificador: "rudyvez-gourmet", },
            { nome: "RU-PINTURAS", identificador: "ru-pinturas", },
            { nome: "Russo com Russa", identificador: "russocomrussa", },
            { nome: "Ruth Mary", identificador: "ruthmarykay", },
            { nome: "R Valentin", identificador: "rvalentin", },
            { nome: "rvvariedades", identificador: "rutymodas39gmailcom", },
            { nome: "Sabará", identificador: "httpsinstagramcomjeanpatryckutmmedi", },
            { nome: "sabor Divino esfiharia", identificador: "sabor-divino-gmailcom", },
            { nome: "Saborear", identificador: "saboreardegustecomprazer", },
            { nome: "Saboresdecasa213", identificador: "saboresdecasa213", },
            { nome: "Saboroso Alimentos", identificador: "saboroso", },
            { nome: "sabor real", identificador: "marmita-real", },
            { nome: "sade", identificador: "sadecuidaremoscomamor", },
            { nome: "SAE Serviços Autônomos de Elétrica", identificador: "sae", },
            { nome: "SAE Serviços Autônomos de Elétrica", identificador: "saeservicosautomosdeeletrica", },
            { nome: "Salão RV", identificador: "rvsalameilojacom", },
            { nome: "SALGADOS DELÍCIA", identificador: "salgadosdelicia", },
            { nome: "Salgados e Hot Dog da Mina", identificador: "salgadosehotdogdamina", },
            { nome: "Salgado Toscano", identificador: "salgadostoscano", },
            { nome: "samarasalgados", identificador: "samarasalgados", },
            { nome: "Sancred", identificador: "atacado-varejo", },
            { nome: "Sandra estore", identificador: "sandra-estore", },
            { nome: "@sandrorochaportoes", identificador: "sandrorochaportoesnegocio", },
            { nome: "SANTIAGO VIAGENS E EXCURSÕES", identificador: "santiagoviagens", },
            { nome: "Sara &mari", identificador: "sara", },
            { nome: "sara venda online", identificador: "25bd1e70-2281-4354-9b73-28a4f08a117", },
            { nome: "Saúde no Prato Marmitaria", identificador: "saudenopratomarmitaria", },
            { nome: "S.B.Mulamb's Ateliê", identificador: "beatriz3330", },
            { nome: "scooby doo lanches", identificador: "scooby-doolanches", },
            { nome: "sdmontagem de móveis", identificador: "danielmacielbarracasgmailcom", },
            { nome: "Sea eletrica e reformas", identificador: "httpscadastroempresacombrcnpj409670", },
            { nome: "seco produção", identificador: "construseco", },
            { nome: "Séculos", identificador: "sculos", },
            { nome: "Segurança Eletrônica", identificador: "seguranca", },
            { nome: "selaria PH", identificador: "selariaph", },
            { nome: "Selma lembrancinhas", identificador: "selma-lembrancinhas", },
            { nome: "sempre.limpo", identificador: "semprelimpo", },
            { nome: "Sempre Perto", identificador: "sempreperto", },
            { nome: "Senhorita Boleira", identificador: "senhoritaboleira", },
            { nome: "Sentidos Confidencial Store", identificador: "sentidosconfidencialstore", },
            { nome: "Sergio Altomação", identificador: "sergioserralheiro", },
            { nome: "Sergio Eletro", identificador: "sergioeletro", },
            { nome: "Sérgio taxi", identificador: "meigratis", },
            { nome: "serralheria Emanuel de paulo", identificador: "emanuel-de-paulo", },
            { nome: "serralheria Rodrigues", identificador: "arte-ferro", },
            { nome: "serralheria Teixeira PORTÕES", identificador: "teixeira-portes", },
            { nome: "serviço de reparos em manutenção", identificador: "loja-material-de-construcao-reforma", },
            { nome: "servicom lavanderia", identificador: "servicom-lavanderia", },
            { nome: "serviços", identificador: "faztudo", },
            { nome: "serviços de entrega", identificador: "entrega-express", },
            { nome: "serviços doméstico", identificador: "damianavitoria", },
            { nome: "Sevilha Segurança Eletrônica", identificador: "sevilha-seguranca", },
            { nome: "Sex shop Bebedouro", identificador: "alcateiagrifes-sexshopbebedouro", },
            { nome: "Shammah lanches  Deus está presente", identificador: "lanchesshammah", },
            { nome: "shopping Dubigod", identificador: "shopping-dubigod", },
            { nome: "Shop Pronta-Entrega", identificador: "shopprontaentrega", },
            { nome: "Sid produções", identificador: "sid-producoes", },
            { nome: "Silvana moda íntima", identificador: "silmoda", },
            { nome: "Silva Serralheria", identificador: "silvaserralheria", },
            { nome: "Silvia fit Alimentação Saudável", identificador: "alimentacaosaudavelrj", },
            { nome: "Silvinha locações", identificador: "silviafestaseventos", },
            { nome: "Simple Wine", identificador: "simplewineba", },
            { nome: "Singela Papelaria", identificador: "singelapapelaria", },
            { nome: "Sioli Express", identificador: "sioliexpress", },
            { nome: "Siqueira Tecno e Publicidade", identificador: "siqueiratecno", },
            { nome: "Sj perfumes", identificador: "lojameisjperfumes", },
            { nome: "SmartConsertec", identificador: "smartconsertec-eletronicos", },
            { nome: "Smart Tech", identificador: "httpsinstagramcomsmarttechdrutmmedi", },
            { nome: "Smart Tech Assistência técnica", identificador: "smarttech", },
            { nome: "SÓ JEANS", identificador: "jeans", },
            { nome: "Solar Plus", identificador: "solar-plus", },
            { nome: "Sol Cayena Viajes", identificador: "solcayenaviajes", },
            { nome: "sollaços", identificador: "sollacos", },
            { nome: "Sonhar", identificador: "sonharcombr", },
            { nome: "SOS  DISTRIBUIDORA", identificador: "clautecdistribuidora", },
            { nome: "SOS Pimenta", identificador: "sospimenta", },
            { nome: "Spaghetti do Ro", identificador: "spaghettidoro", },
            { nome: "SP CAPITAL DIGITAL", identificador: "spcapitaldigital", },
            { nome: "Spinstore", identificador: "spinstore", },
            { nome: "sr batata conveniência", identificador: "srbatata-convenincia", },
            { nome: "Sr Faz Tudo", identificador: "srfaztudo", },
            { nome: "ssistema de segurança", identificador: "httpssistemadesegurancawordpresscom", },
            { nome: "SS loja do tempo", identificador: "lojadotempo", },
            { nome: "Steel usinagem", identificador: "gmailcom", },
            { nome: "Stella Albuquerque Patisserie", identificador: "stellaalbuquerque-patisserie", },
            { nome: "STETCAR", identificador: "stetcar27", },
            { nome: "s&t_higienizaçoes", identificador: "sthigienizacao", },
            { nome: "stopcar", identificador: "luiz-almeida", },
            { nome: "store Camila severo", identificador: "camilamodas83", },
            { nome: "Studio Alves Music", identificador: "studio-alvesmusic", },
            { nome: "studio bruno falcão", identificador: "studiobrunofalcao", },
            { nome: "Studio Dudah", identificador: "studiodudan", },
            { nome: "Studiokardasha", identificador: "httpsinstagramcomstudiokardashautmm", },
            { nome: "STUDIO LUH DANCE", identificador: "luhdanceeventos", },
            { nome: "Studio Lu Soares", identificador: "studiolusoares", },
            { nome: "Studio Pro Fitness", identificador: "studioprofitnessbj", },
            { nome: "Studio R.B", identificador: "httpsinstagramcomrodrigodeyosutmmed", },
            { nome: "Stúdio Vanubia Amorim", identificador: "vanubiaamorim-24", },
            { nome: "stylos", identificador: "url", },
            { nome: "sublissima", identificador: "sublissima", },
            { nome: "Super Conquista", identificador: "superconquistaoficial", },
            { nome: "super garfo marmitaria", identificador: "supergarfomarmitexloja1", },
            { nome: "Sushi Roll e Yakissoba Delivery", identificador: "httpssushi-roll-yakissoba-deliveryo", },
            { nome: "svm móveis planejados", identificador: "svm-moveis-planejados", },
            { nome: "Sweet desire", identificador: "httpsinstagramcomsweetdesireofutmme", },
            { nome: "Swelyane Cosmetics For Princess", identificador: "swelyane-cosmetics", },
            { nome: "tabacaria asas golden", identificador: "asas-golden", },
            { nome: "Tabacaria & Imports WY", identificador: "tabacariawy", },
            { nome: "Tacio Pintor", identificador: "taciopintor", },
            { nome: "Taju sabores da vida", identificador: "tajusaboresdavida", },
            { nome: "TAKASE VENDAS", identificador: "takasevendas", },
            { nome: "tamaravilhosa", identificador: "httpslinkmebiotamaravilhosafitstore", },
            { nome: "Tamirys modas & variedades", identificador: "tamirys-modas-variedades", },
            { nome: "TapBurguer", identificador: "tapburguer", },
            { nome: "Tebas Esportivos", identificador: "tebasesportivos", },
            { nome: "Tempero De Casa Delivery", identificador: "httpsinstagramcomrestaurantetemperodecasa21utmmediumcopylink", },
            { nome: "Tempero D'Elas", identificador: "delastemperodelas", },
            { nome: "Tenda do Dan", identificador: "httpsshopstorecompanysualojaonlinea", },
            { nome: "tendetudo", identificador: "tendetudo", },
            { nome: "teste", identificador: "teste", },
            { nome: "Tga cosmeticos", identificador: "tgacosmeticos", },
            { nome: "thallyta moda e acessórios", identificador: "thallytamodaeacessorios", },
            { nome: "The best gourmet", identificador: "httpsinstagramcomthebestgourmettutm", },
            { nome: "The Family Burger", identificador: "thefamilyburguer", },
            { nome: "ThiLy Conveniência e tabacaria", identificador: "thily", },
            { nome: "TILLINHOBABY", identificador: "tillinhobaby-atacado-riodaspedras", },
            { nome: "TMODAS", identificador: "httpbitlytmodas", },
            { nome: "Tokyo Pasrwl", identificador: "httpswwwifoodcombrdeliverymanaus-am", },
            { nome: "Topcell_acessoriosac", identificador: "topcellacessoriosac", },
            { nome: "top eletro sat", identificador: "topeletrosat", },
            { nome: "top\"moda", identificador: "topmoda", },
            { nome: "Top New Rafaela Mallio", identificador: "topnewrafaelamallio", },
            { nome: "TOP TOP STARS VENDAS", identificador: "toptopstarsvendas", },
            { nome: "TOP TV STREAMING", identificador: "toptv", },
            { nome: "toque de magica personalizados", identificador: "toquedemagica", },
            { nome: "TRANSRAPIDO", identificador: "transrapido", },
            { nome: "Travessos Artes&Artesanato", identificador: "travessosaa", },
            { nome: "Triunffo Jack Miranda", identificador: "triunffo-jack-miranda", },
            { nome: "tron manutenção e serviços", identificador: "cnpj-30061484000140", },
            { nome: "T.R.T AÇAÍ", identificador: "trtacai", },
            { nome: "Tshirts Moriah", identificador: "jeezshirts", },
            { nome: "Tudo em Obras e gesso", identificador: "httpswwwfacebookcomadrianopires7792", },
            { nome: "tudo sobre pintura", identificador: "ivanildomartins80gmailcom", },
            { nome: "Ultratex", identificador: "empresa-ultratex", },
            { nome: "uma pela outra ❤️", identificador: "umapelaoutra", },
            { nome: "UniDuniKids", identificador: "unidunikids", },
            { nome: "Uniiarts Brindes Personalizados", identificador: "uniiarts", },
            { nome: "UP Soluções", identificador: "upsolucoes", },
            { nome: "Use & Abuse L'amour", identificador: "httpsinstagramcomuseeabuselamourutm", },
            { nome: "Use Duo", identificador: "lojauseduo", },
            { nome: "useexcitame", identificador: "useexcitame", },
            { nome: "ustulin climatização", identificador: "ustulin-climatizao", },
            { nome: "VAI Elétrica Serviços", identificador: "vaieletricaservicos", },
            { nome: "Valettinho João Pessoa", identificador: "valettinhojp", },
            { nome: "vandi entregas etc", identificador: "vandipereira2013hotmailcom", },
            { nome: "variedades", identificador: "fabiano-santos", },
            { nome: "variedades jef", identificador: "variedades-jef", },
            { nome: "Varitudo", identificador: "varitudo", },
            { nome: "V&C Higienização de estofados em geral.", identificador: "vc-higienizao-de-estofados-em-geral", },
            { nome: "velas incovel", identificador: "velas-incovel", },
            { nome: "vendas artigos religião", identificador: "httpswamemessagewcds52mdiyvja1", },
            { nome: "vendas da Suelen", identificador: "suelen-mayara-19", },
            { nome: "Venon Collection e Variedades", identificador: "venoncollection", },
            { nome: "Venture Sex Shop", identificador: "venturesexshop", },
            { nome: "VFINFORMÁTICA", identificador: "vfinformatica", },
            { nome: "VIDA SAUDAVEL ALIMENTOS", identificador: "vidasaudavelalimentos", },
            { nome: "vidraçaria e serralheria casa do serralheiro", identificador: "vidraariaeserralheria", },
            { nome: "vidraçaria Garcia", identificador: "no-tem", },
            { nome: "Vidraçaria Nalevaiko", identificador: "vidros-sjbv", },
            { nome: "vidro & cia", identificador: "vidroeciameigrates", },
            { nome: "Vigiar Câmeras e Alarmes", identificador: "vigiarsys", },
            { nome: "ViPe Móveis Planejados", identificador: "vipemoveis", },
            { nome: "VIP.NET.TELECOM", identificador: "vipnettelecom", },
            { nome: "Viveiro Encanto Verde", identificador: "viveiro-encanto-verde", },
            { nome: "VK Soluções em Crédito", identificador: "vk-credito", },
            { nome: "VL Lustres", identificador: "httpswamec553191386977", },
            { nome: "vmultilidades", identificador: "vmultilidades", },
            { nome: "Vocação Jurídica com Roberta Reynaud", identificador: "vocacaojuridica", },
            { nome: "VR Trading", identificador: "vrtrading", },
            { nome: "VS MOTO PEÇA SHOW", identificador: "vsmotopecashow", },
            { nome: "VWD construçoes e reformas", identificador: "vwd-construcoes", },
            { nome: "WAPINTURAS", identificador: "wapintiraslmtda", },
            { nome: "Wasem Service", identificador: "wasemservice", },
            { nome: "watts lanches Rosa e Edu", identificador: "lojameigratisapplojameigratis", },
            { nome: "Wdn Energia", identificador: "wdnenergia", },
            { nome: "weller Vitor", identificador: "wellervitor", },
            { nome: "WEMERSON ALVES DA SILVA", identificador: "esteticaerepinturaautomotiva", },
            { nome: "Wine house", identificador: "winehouse", },
            { nome: "W.M manutenção e estrura Metallica", identificador: "wm-estruturas", },
            { nome: "wm tudo em um só luga", identificador: "weliton2100gmailcom", },
            { nome: "wn Limpeza", identificador: "wnlimpeza-loja-produtos", },
            { nome: "Wolf Modas Unissex", identificador: "wolfmodaunissex", },
            { nome: "Workout Shop Suplementos", identificador: "workout-shop", },
            { nome: "WP Personalizados", identificador: "wppersonalizados", },
            { nome: "wsp vidros", identificador: "lojameigratis", },
            { nome: "W.S Sabor de mel", identificador: "wellingtongoncalves5205gmailcom", },
            { nome: "xiaomi play", identificador: "xiaomi", },
            { nome: "yra empério do gesso 3d e placas cimenticias", identificador: "yra-gesso-e-placas-cimenticias", },
            { nome: "zabeda enxovais", identificador: "lojazabedaenxovais", },
            { nome: "Zaji Representações", identificador: "httpsbitly33cyr95", },
            { nome: "Zayer Shop", identificador: "zayershop", },
            { nome: "Zhaya Laços e Acessórios", identificador: "zhaya40", },
            { nome: "zuzu", identificador: "zuzuhugs", },
        ];
    }

    function buscaLoja(idLoja) {
        return axios
            .get(
                `https://s3.sa-east-1.amazonaws.com/dados.loja.meigratis.app/${idLoja}`
            )
            .then((resposta) => resposta.data)
            .catch((erro) => console.log(erro));
    }

    function criaPedido(idLoja, dadosPedido) {
        return axios
            .post(
                `${process.env.VUE_APP_BASE_API_URL}/api/meigratis/loja/${idLoja}/pedidos`,
                dadosPedido,
            )
            .then((resultado) => resultado.data.dados)
    }

    return { obtemLojas, buscaLoja, criaPedido };
}